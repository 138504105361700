import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Select,
  Container,
  Button,
  Chip,
  MenuItem,
  Input,
  Grid,
  InputLabel,
  FormControl,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import { addAlert } from "../../store/reducers/slices/configSlice";
import { useSelector, useDispatch } from "react-redux";
import { postConfigTaskAdd, postConfigTaskEdit, deleteConfigTaskType } from "../../store/reducers/slices/configSlice";


function getStyles(type, typeName, theme) {
  return {
    fontWeight:
      typeName?.indexOf(type) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 'calc(100%)'
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default function EditTaskType({ taskType, mode, closeModal, getTypeData }) {
  const dispatch = useDispatch()
  const classes = useStyles();
  const theme = useTheme();
  const [typeName, setTypeName] = useState(taskType.task_dependency_id);
  const [nightTask, setNightTask] = useState(taskType.nightTask);
  const [active, setActive] = useState(taskType.is_active);
  const [nameSet, setNameSet] = useState(taskType.task_type_name);
  const [taskTypeId, setTaskTypeId] = useState(taskType.task_type_id);
  const [askDelete, setAskDelete] = useState(false)


  const { typeData } = useSelector((state) => state.manageConfigData);

  const handleChange = (event) => {
    setTypeName(event.target.value);
  };

  const handleNightTask = (event) => {
    setNightTask(event.target.checked);
  };

  const handleActive = (event) => {
    setActive(event.target.checked);
  };

  const handleNameChange = (event) => {
    setNameSet(event.target.value)
  }

  const createType = async (event) => {
    event.preventDefault();

    let task_dependency_id = []
    for (let index = 0; index < typeName.length; index++) {
      let temp = typeData.find(({ task_type_name }) => task_type_name === typeName[index])
      task_dependency_id.push(temp.task_type_id)
    }
    task_dependency_id = task_dependency_id.toString()

    const newTypeData = {
      task_type_name: nameSet,
      task_dependency_id: task_dependency_id,
      is_active: active,
      nightTask: nightTask,
      task_type_id: taskTypeId
    };

    if (mode === "Add") {
      dispatch(
        postConfigTaskAdd(
          newTypeData
        )
      ).then(() => {
        getTypeData()
      })
    } else if (mode === "Edit") {
      dispatch(
        postConfigTaskEdit(
          newTypeData
        )
      ).then(() => {
        getTypeData();

      })
    }
    closeModal();
  };

  const askDeleteType = () => {
    setAskDelete(true)
  }

  const cancelDeleteType = () => {
    setAskDelete(false)
    dispatch(
      addAlert({
        open: true,
        severity: 'warning',
        message: 'The task type has not been deleted'
      })
    )
  }

  const deleteType = async () => {
    dispatch(
      deleteConfigTaskType({
        nameSet,
        taskTypeId
      })
    ).then(() => {
      getTypeData();
      closeModal();
    })
  }

  return (
    <Container fixed>
      <Grid
        item
        md={12}
        xs={12}
        style={{
          boxShadow: "rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem",
          backgroundColor: "rgb(255, 255, 255)",
          border: "0px solid rgba(0, 0, 0, 0.125)",
          borderRadius: "1rem",
          padding: "30px",
          minWidth: "350px"
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box>
            <Typography variant="h5" style={{ textAlign: "center" }}>Editing Type</Typography>
          </Box>
          <form onSubmit={createType} style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
            <Box display="flex" flexDirection='column' alignItems="center" marginTop="1.5em">
              <TextField
                required
                id="outlined-basic"
                name="typeName"
                label="Type Name"
                variant="outlined"
                fullWidth
                value={nameSet}
                onChange={handleNameChange}
              />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-chip-label">Dependent on</InputLabel>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={typeName}
                  onChange={handleChange}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {typeData.map((type) => (
                    <MenuItem
                      key={type.task_type_id}
                      value={type.task_type_name}
                      style={getStyles(type, typeName, theme)}
                    >
                      {type.task_type_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center" marginTop="1.5em">
              <FormControlLabel control={<Switch
                checked={nightTask}
                onChange={handleNightTask}
                color="primary"
                name="nightTask"
                inputProps={{ "aria-label": "Night task?" }}
              />}
                label="Night Task" />
              <FormControlLabel control={<Switch
                checked={active}
                onChange={handleActive}
                color="primary"
                name="active"
                inputProps={{ "aria-label": "Active?" }}
              />}
                label="Active" />
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center" marginTop="2em">
              {askDelete ?
                <>
                  <Button style={{ marginRight: '5px' }} variant="contained" style={{ backgroundColor: '#DD3333', color: 'white' }} onClick={deleteType}>Confirm Delete</Button>
                  <Button style={{ marginLeft: '5px' }} variant="contained" color="primary" onClick={cancelDeleteType}>Cancel</Button>
                </>
                :
                <>
                  <Button style={{ marginRight: '5px' }} variant="contained" color="primary" type="submit">{mode === "Add" ? "Create Type" : "Update Type"}</Button>
                  {mode === "Edit" ?
                    <Button style={{ marginLeft: '5px' }} variant="contained" color="primary" onClick={askDeleteType}>Delete Job</Button> : null}
                </>
              }
            </Box>
          </form>
        </Box>
      </Grid>
    </Container>
  );
}

import { Dialog, DialogContent, DialogTitle } from "@material-ui/core"
import { useState, useEffect } from "react"
import ReactTable from "./ReactTable";
import DefaultColumnFilter from "../ReactTable/DefaultColumnFilter";
import { useDispatch, useSelector } from "react-redux";
import { fetchQueuedTaskWithErrorDetails } from "../../../store/reducers/slices/errorTableSlice";

const ViewErrorDialog = ({ handleClose, open }) => {

  const dispatch = useDispatch();
  const { errorQueueData, errorTaskQueueId } = useSelector((state) => state.manageErrorTable);

  // useEffect(() => {
  //   if (taskQueueId.length > 0) {
  //     console.log('taskQueueId : ', taskQueueId)
  //     dispatch(fetchQueuedTaskWithErrorDetails({ task_queue_id: taskQueueId }))
  //   }
  // }, [taskQueueId])

  useEffect(() => {
    console.log('errorTaskQueueId : ', errorTaskQueueId)
    dispatch(fetchQueuedTaskWithErrorDetails({ task_queue_id: errorTaskQueueId }))
  }, [errorTaskQueueId])

  useEffect(() => {
    console.log('errorQueueData : ', errorQueueData);
  }, [errorQueueData])

  const columnsForTable = [
    {
      Header: "Task queue id",
      id: "task_queue_id",
      accessor: (t) => t.task_queue_id,
      Filter: DefaultColumnFilter,
      Cell: (params) => params.cell.value
    },
    {
      Header: "Error id",
      id: "error_id",
      accessor: (t) => t.error_id,
      Filter: DefaultColumnFilter,
      Cell: (params) => params.cell.value
    },
    {
      Header: "Error message",
      id: "error_message",
      accessor: (t) => t.error_message,
      Filter: DefaultColumnFilter,
      Cell: (params) => params.cell.value
    },
  ]

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xl">
      <DialogTitle>Task queue error logs</DialogTitle>
      <DialogContent>
        {errorQueueData.length > 0 && (
          <ReactTable
            columns={columnsForTable}
            data={errorQueueData}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ViewErrorDialog;
import {
  CardActions,
  CardContent,
  Box,
  Typography,
  Button,
  CardHeader,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const CardItem = ({ selectiveCount, totalCount, linkUrl, header }) => {
  const { selectedTaskType } = useSelector(
    (state) => state.manageDashboardData
  );

  const [disable, setDisable] = useState(false);

  useEffect(() => {
    totalCount > 0 || selectiveCount > 0 ? setDisable(false) : setDisable(true)
  }, [totalCount, selectiveCount])

  return (
    <Card variant="outlined">
      <CardHeader
        title={`${header}:`}
        style={{
          backgroundColor: "#005170",
          color: "#fff",
          textAlign: "center",
        }}
      ></CardHeader>
      <CardContent>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>
            <Box marginTop="0.25em">
              <Typography variant="h4" align="center">
                <CountUp
                  end={selectedTaskType === 0 ? totalCount : selectiveCount}
                  duration={1.5}
                  separator=","
                />
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
      <CardActions style={{ justifyContent: "center" }}>
        <Link to={linkUrl} style={disable ? { textDecoration: "none", pointerEvents: "none" } : { textDecoration: "none" }}>
          <Button
            variant="contained"
            color="primary"
            style={{
              paddingTop: "0.75em",
              paddingBottom: "0.75em",
              paddingLeft: "2.50em",
              paddingRight: "2.50em",
            }}
            disabled={disable}
          >
            View
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
};

export default CardItem;

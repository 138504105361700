import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Modal,
  IconButton,
  Icon,
} from "@material-ui/core";
import EditTaskType from "./EditTaskType";
import { useSelector, useDispatch } from "react-redux";
import { fetchConfigTaskTypes } from "../../store/reducers/slices/configSlice";
import ButtonReactTable from "../tables/ButtonReactTable";
import SelectColumnFilter from "../tables/ReactTable/SelectColumnFilter";
import DefaultColumnFilter from "../tables/ReactTable/DefaultColumnFilter";

export default function SwitchBoardTaskTypes() {
  const dispatch = useDispatch();
  const { typeData } = useSelector((state) => state.manageConfigData);

  const [typeMode, setTypeMode] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState({});

  const getTypeData = async () => {
    dispatch(fetchConfigTaskTypes());
  };

  const createType = () => {
    setEditData({
      task_type_name: "",
      task_dependency_id: [],
      is_active: true,
      nightTask: false,
      task_type_id: typeData.at(-1).task_type_id + 1,
    });
    setTypeMode("Add");
    setShowEdit(true);
  };

  const editType = (item) => {
    let editItem = { ...item.original };
    if (typeof editItem.task_dependency_id === "string") {
      editItem.task_dependency_id = editItem.task_dependency_id.split(",");
      let task_dependency_id = [];
      editItem.task_dependency_id.forEach((typeId) => {
        const temp = typeData.find(
          ({ task_type_id }) => task_type_id.toString() === typeId
        );
        if (temp) {
          task_dependency_id.push(temp.task_type_name);
        }
      });
      editItem.task_dependency_id = task_dependency_id;
    } else if (!isNaN(editItem.task_dependency_id[0])) {
      let task_dependency_id = [];
      editItem.task_dependency_id.forEach((typeId) => {
        const temp = typeData.find(
          ({ task_type_id }) => task_type_id.toString() === typeId
        );
        if (temp) {
          task_dependency_id.push(temp.task_type_name);
        }
      });
      editItem.task_dependency_id = task_dependency_id;
    }
    setEditData(editItem);
    setTypeMode("Edit");
    setShowEdit(true);
  };

  const handleClose = () => {
    setTypeMode("");
    setShowEdit(false);
  };

  const dependenciesForTable = (params) => {
    if (params.task_dependency_id !== "0" && params.task_dependency_id !== "") {
      let dependencyNameOutput = "";
      let tempArr = [];
      tempArr.push(params.task_dependency_id);
      const filtered = tempArr[0]
        .replace(/\D+/g, " ")
        .trim()
        .split(" ")
        .map((e) => parseInt(e));
      tempArr = [];
      filtered.forEach((i) => {
        typeData.forEach((j) => {
          if (i === j.task_type_id) {
            tempArr.push({
              task_type_id: j.task_type_id,
              task_type_name: j.task_type_name,
            });
          }
        });
      });
      tempArr.forEach((i) => {
        dependencyNameOutput += i.task_type_name + ", ";
      });
      return (
        <>
          {dependencyNameOutput.substring(0, dependencyNameOutput.length - 2)}
        </>
      );
    } else {
      return <>None</>;
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    p: 4,
  };

  useEffect(() => {
    if (typeData.length === 0) {
      getTypeData();
    }
  }, []);

  const columns = [
    {
      accessor: "task_type_name",
      id: "task_type_name",
      Header: "Type Name",
      Filter: DefaultColumnFilter,
    },
    {
      accessor: (originalRow) => originalRow['is_active'] ? "Active" : "Offline",
      id: "is_active",
      Header: "Active",
      Filter: SelectColumnFilter,
    },
    {
      accessor: (originalRow) => dependenciesForTable(originalRow),
      id: "task_dependency_id",
      Header: "Dependencies",
      disableFilters: true,
    },
    {
      accessor: (originalRow) => originalRow['nightTask'] ? "Night task" : "Day task",
      id: "nightTask",
      Header: "Task schedule",
      Filter: SelectColumnFilter,
    },
    {
      accessor: "Edit",
      id: "Edit",
      Header: "Edit",
      disableSortBy: true,
      disableFilters: true,
      Cell: (params) => (
        <strong>
          <IconButton onClick={() => editType(params.row)}>
            <Icon className="fa fa-edit" />
          </IconButton>
        </strong>
      ),
    },
  ];

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Box marginBottom="1em">
          <Typography component={"span"} variant="h5">
            Type Manager:
          </Typography>
        </Box>
        {typeData && typeData.length > 0 ? (
          <ButtonReactTable
            data={typeData}
            columns={columns}
            id={"task-type-table"}
            initialState={{
              pageSize: 10,
              sortBy: [
                { id: 'Type Name', desc: false }
              ],
            }}
            tableName='taskTypeTable'
            persistState
          />
        ) : (
          <Typography variant="h5">Could not connect to Database</Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        <Button
          variant="contained"
          color="primary"
          onClick={createType}
          disabled={!typeData || typeData.length === 0}
        >
          Add Type
        </Button>
      </Box>
      <Modal
        open={showEdit}
        onClose={handleClose}
        aria-labelledby="edit task type"
      >
        <Box sx={modalStyle}>
          <EditTaskType
            taskType={editData}
            mode={typeMode}
            closeModal={handleClose}
            getTypeData={getTypeData}
          />
        </Box>
      </Modal>
    </>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_TASK_TYPES,
  ERROR_TABLE_DATA,
  GET_COUNT_FOR_EACH_TYPE,
  GET_ERRORED_TABLE_DATA_FULL,
  GET_ERRORED_QUEUE_DATA,
} from "../../types";
import axios from "axios";
import format from "date-fns/format";

const DATE_FORMAT = 'yyyy-MM-dd';

const { REACT_APP_API_BASE_URI } = process.env;
const params = {
  headers: {
    deviceToken: "0e07b48d-b30f-4e02-b554-f23aad4c4b35",
    "Access-Control-Allow-Origin": "*",
    "x-jwt-token":
      "eyJhbGciOiJIUzI1NiJ9.eyJkZXZpY2VVVUlEIjoiZjBmOTkxZGYtMjY3Zi0zMzUxLThhNmYtZjg1MTE4M2YwMzhjIiwicGVybWlzc2lvbnMiOlt7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BTExPQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjYyfSx7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BUFAiLCJwZXJtaXNzaW9uX2lkIjo2MX0seyJwZXJtaXNzaW9uX25hbWUiOiJXQVJFSE9VU0VfT1BFUkFUSU9OUyIsInBlcm1pc3Npb25faWQiOjY3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFMTCIsInBlcm1pc3Npb25faWQiOjN9LHsicGVybWlzc2lvbl9uYW1lIjoiUEhPVE9fUElDS1MiLCJwZXJtaXNzaW9uX2lkIjoxNjJ9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6MTAyfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0FQUCIsInBlcm1pc3Npb25faWQiOjEwMX0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9BUElfU1RBVFVTIiwicGVybWlzc2lvbl9pZCI6MTEzfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0JSQU5DSCIsInBlcm1pc3Npb25faWQiOjEwOH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9FUkVDRUlQVFMiLCJwZXJtaXNzaW9uX2lkIjoxMDl9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fRVJFQ0VJUFRTX0NPTkZJRyIsInBlcm1pc3Npb25faWQiOjExMH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9HUkFQSFMiLCJwZXJtaXNzaW9uX2lkIjoxMDN9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVkiLCJwZXJtaXNzaW9uX2lkIjoxMTF9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVlfTk9USUZJQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjExMn0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVCIsInBlcm1pc3Npb25faWQiOjEwNH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVF9DT05GSUciLCJwZXJtaXNzaW9uX2lkIjoxMDV9LHsicGVybWlzc2lvbl9uYW1lIjoiRVBNX1BJQ0tJTkciLCJwZXJtaXNzaW9uX2lkIjo2M30seyJwZXJtaXNzaW9uX25hbWUiOiJFUE1fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6NjR9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fU05fQ09ORklHIiwicGVybWlzc2lvbl9pZCI6MTA3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX1NOIiwicGVybWlzc2lvbl9pZCI6MTA2fV19.7mZNEGYGhUxHpZF-D5UBqkv7BRm0g6HgesQ2EXBudds",
  },
};

// increase width for dialog, have two seperate queries in procedure -> and if vertical, scroll bar is visible

export const fetchErroredDataInFull = createAsyncThunk(
  GET_ERRORED_TABLE_DATA_FULL,
  async (dataParams, { rejectWithValue }) => {
    const { type_id, start_date, end_date } = dataParams;
    const tempStartDate = format(start_date, DATE_FORMAT);
    const adjustEndDate = new Date(end_date);
    adjustEndDate.setDate(adjustEndDate.getDate() + 1);
    const tempEndDate = format(end_date, DATE_FORMAT);
    // console.log('tempstart: ', tempStartDate);
    // console.log('tempend : ', tempEndDate);
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getErroredTasksInFull?type_id=${type_id}&start_date=${tempStartDate}&end_date=${tempEndDate}`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue("Unable to connect");
      }
      return response.data.erroredTasks;
    } catch (err) {
      console.error(err);
      return rejectWithValue("Unable to connect");
    }
  }
);

export const getCountForAllTableTaskTypes = createAsyncThunk(
  GET_COUNT_FOR_EACH_TYPE,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getCountForAllTableTaskTypes`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue("Unable to connect");
      }
      if (response.data.countForAllTableTaskTypes.length > 0) {
        return response.data.countForAllTableTaskTypes;
      } else {
        return [];
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Unable to connect");
    }
  }
);

export const fetchAllTaskTypes = createAsyncThunk(
  GET_TASK_TYPES,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getTaskTypes`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue("Unable to connect");
      }
      return response.data.types;
    } catch (err) {
      console.error(err);
      return rejectWithValue("Unable to connect");
    }
  }
);

export const fetchQueuedTaskWithErrorDetails = createAsyncThunk(
  GET_ERRORED_QUEUE_DATA,
  async (dataParams, { rejectWithValue }) => {
    const { task_queue_id } = dataParams;
    console.log('task_queue_id : ', task_queue_id)
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/checkIfTaskHasErrored?TaskQueueId=${task_queue_id}`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue("Unable to connect");
      }

      console.log('response for getting error details : ', response);
      return response.data;
    } catch (err) {
      console.error(err);
      return rejectWithValue("Unable to connect");
    }
  }
);

export const updateErroredTaskToQueue = createAsyncThunk('UPDATE_ERROR_TASK_TO_QUEUE',
  async (data, { rejectWithValue }) => {
    console.log('hello?', data)
    console.log('data in update erroed taks to queu : ', data)
    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/updateErroredTaskToQueue`,
        data,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
        return response;
      } else {
        return rejectWithValue("Unable to connect");
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Unable to connect");
    }
  }
);

const errorTableSlice = createSlice({
  name: ERROR_TABLE_DATA,
  initialState: {
    data: [],
    taskTypes: [],
    loading: false,
    toastError: false,
    toastWarning: false,
    toastSuccess: false,
    errorForEachType: [],
    paginationTaskTotal: 0,
    requestSort: false,
    completedSort: false,
    queueSort: false,
    loadingTableData: false,
    postTasksToQueueLoading: false,
    errorSelectedTaskType: 0,
    errorQueueData: {},
    errorTaskQueueId: ''
  },
  reducers: {
    updateTable: (state, action) => {
      if (action.payload.addNewDataToTable.length > 0) {
        state.data = [...action.payload.addNewDataToTable];
      } else {
        state.data = [];
        state.toastWarning = true;
      }
    },

    setErrorTaskQueueId: (state, action) => {
      state.errorTaskQueueId = action.payload;
      state.loadingTableData = false;
    },

    setPaginationTaskTotal: (state, action) => {
      state.paginationTaskTotal = action.payload;
    },

    setToastError: (state, action) => {
      state.toastError = action.payload;
    },

    setToastWarning: (state, action) => {
      state.toastWarning = action.payload;
    },

    setToastSuccess: (state, action) => {
      state.toastSuccess = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchErroredDataInFull.fulfilled, (state, action) => {
      state.toastWarning = false;
      if (action.payload !== undefined) {
        state.data = [...action.payload];
        state.loadingTableData = false;
      } else {
        state.data = [];
        state.toastWarning = true;
        state.loadingTableData = false;
      }
    });

    builder.addCase(fetchErroredDataInFull.pending, (state) => {
      state.loadingTableData = true;
    });

    builder.addCase(fetchErroredDataInFull.rejected, (state) => {
      state.loadingTableData = false;
      state.toastError = true;
    });

    builder.addCase(getCountForAllTableTaskTypes.fulfilled, (state, action) => {
      state.loading = false;
      state.errorForEachType = action.payload[2];
    });

    builder.addCase(getCountForAllTableTaskTypes.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getCountForAllTableTaskTypes.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchAllTaskTypes.fulfilled, (state, action) => {
      state.loading = false;
      state.taskTypes = action.payload;
    });

    builder.addCase(fetchAllTaskTypes.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchAllTaskTypes.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchQueuedTaskWithErrorDetails.fulfilled, (state, action) => {
      state.toastWarning = false;
      if (action.payload !== undefined) {
        // state.data = [...action.payload];
        // state.loadingTableData = false;
        state.errorQueueData = action.payload;
      } else {
        // state.data = [];
        state.toastWarning = true;
      }
    });

    builder.addCase(fetchQueuedTaskWithErrorDetails.pending, (state) => {
      // state.loadingTableData = true;
    });

    builder.addCase(fetchQueuedTaskWithErrorDetails.rejected, (state) => {
      state.loadingTableData = false;
      state.toastError = true;
    });

    builder.addCase(updateErroredTaskToQueue.fulfilled, (state, action) => {
      console.log("successfully updated errored task : ", action.payload);
      state.postTasksToQueueLoading = false;
      state.toastSuccess = true;
    });

    builder.addCase(updateErroredTaskToQueue.pending, (state, action) => {
      console.log('posting error back to queue pending....', action.payload);
      state.postTasksToQueueLoading = true;
    })

    builder.addCase(updateErroredTaskToQueue.rejected, (state, action) => {
      console.log('action : ', action);
      state.postTasksToQueueLoading = false;
      state.toastError = true;
      console.log("failed to update the errored tasks, and send back into the queue");
    });

    // fetchQueuedTaskWithErrorDetails
  },
});

export const {
  setToastError,
  setToastWarning,
  setToastSuccess,
  setSelectedTaskName,
  setPaginationTaskTotal,
  setErrorSelectedTaskName,
  setErrorSelectedTaskType,
  setErrorTaskQueueId,
} = errorTableSlice.actions;
export default errorTableSlice.reducer;

import { Button, CircularProgress } from "@material-ui/core"
import { useEffect, useState } from "react";

export const ResendButton = ({ taskQueueId, resendTask }) => {

  const [localLoading, setLocalLoading] = useState(false);

  useEffect(() => {
    localLoading && resendTask(taskQueueId);
    setTimeout(() => setLocalLoading(false), 1500)
  }, [localLoading, resendTask, taskQueueId])

  return <Button variant="contained" color="primary" onClick={() => setLocalLoading(true)} disabled={localLoading}>{localLoading ? <CircularProgress size={"1.8rem"} style={{ color: "#fff", margin: "0 3.2em" }} /> : "Send To Queue"}</Button>
}
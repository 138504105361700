import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import Charts from "../components/dashboard/Charts/Charts";
import PaperContainer from "../components/Containers/PaperContainer";
import Toast from "../components/toast/Toast";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchGraphData,
  setGraphErrorToast,
  setGraphWarningToast,
  // setData,
} from "../store/reducers/slices/graphDataSlice";
import {
  fetchLastUpdated,
  fetchAllTaskTypes,
  getTaskData,
  // setTaskTypesWithCounts,
  getAllTaskTypeCounts, // features totals in date ranges.
} from "../store/reducers/slices/dashboardDataSlice";
import Header from "../components/header/Header";
import CardContainer from "../components/dashboard/Cards/CardContainer";

function Analytics({
  setIsSelectedAnalytics,
  setIsSelectedSettings,
  setIsSelectedSearch,
  setIsSelectedMonitoring,
  setIsSelectedBarcodeSearch,
}) {
  const dispatch = useDispatch();
  const {
    startDate,
    endDate,
    toastError,
    toastWarning,
    timeFrame,
    totalOrSelective,
    data,
  } = useSelector((state) => state.manageGraphData);
  const { selectedTaskType } = useSelector(
    (state) => state.manageDashboardData
  );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const refetchInterval = setInterval(() => {
      dispatch(
        fetchGraphData({
          id: selectedTaskType,
          timeFrame: timeFrame ? timeFrame : "Hour",
          isTotalAmount: totalOrSelective ? totalOrSelective : "selective",
          startDate: startDate,
          endDate: endDate,
        })
      );
      dispatch(getTaskData({ startDate, endDate }));
      dispatch(getAllTaskTypeCounts({ startDate, endDate }));
    }, 10000);
    return () => clearInterval(refetchInterval); // This is important
  });

  useEffect(() => {
    setLoading(false);
    setIsSelectedAnalytics(true);
    setIsSelectedSettings(false);
    setIsSelectedSearch(false);
    setIsSelectedMonitoring(false);
    setIsSelectedBarcodeSearch(false);
    dispatch(
      fetchGraphData({
        id: selectedTaskType,
        timeFrame: timeFrame ? timeFrame : "Hour",
        isTotalAmount: totalOrSelective ? totalOrSelective : "selective",
        startDate: startDate,
        endDate: endDate,
      })
    );
    dispatch(getTaskData({ startDate, endDate }));
    dispatch(getAllTaskTypeCounts({ startDate, endDate }));
    dispatch(fetchLastUpdated());
    dispatch(fetchAllTaskTypes());
  }, []);

  const handleToastClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    if (toastError) {
      dispatch(setGraphErrorToast(false));
    } else if (toastWarning) {
      dispatch(setGraphWarningToast(false));
    }
  };

  return (
    <PaperContainer>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        marginX="1em"
      >
        <Header pageName="Analytics" />
        {!loading && (
          <>
            {/* for mobile fix - set the container to d-flex, flex-column in mobile screen format. */}
            <Grid container spacing={2}>
              <Grid
                item
                xs={2}
                style={{
                  marginY: "auto",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {/* for mobile fix inside taskcount container */}
                <CardContainer />
              </Grid>
              {/* for mobile fix - set the container to min-width: 100% */}
              <Grid item xs={10}>
                <Charts />
              </Grid>
            </Grid>
          </>
        )}
        {loading && (
          <Box style={{ height: "100vh" }}>
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          </Box>
        )}
        <Toast
          selectedToast={toastError}
          handleToastClose={() => handleToastClose()}
          alertType="error"
          message="There was a problem getting the results. Try again later."
        />
        <Toast
          selectedToast={toastWarning}
          handleToastClose={() => handleToastClose()}
          alertType="warning"
          message="There are no results for this time gap or type, try a larger time frame."
        />
      </Box>
    </PaperContainer>
  );
}

export default Analytics;

import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import { MonitoringModal } from "./modals/monitoringModal";
import { trafficCardItemStyling } from "../../styles/TrafficCardItemStyling";
import { PlcStatusInnerItem } from "./PlcStatusInnerItem";

export const MonitoringItem = ({ data, plcData, toggleFullWidthBoxes, plcLoading }) => {
  const classes = trafficCardItemStyling();

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [trafficLightSystem, setTrafficLightSystem] = useState({
    hour: 60, // hour
    day: 1440, // day
  });
  const handleOpen = (log) => {
    setModalData(log);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { toggled } = toggleFullWidthBoxes;

  return (
    <>
      <Box className={classes.cardItemContainer}>
        {data?.length > 0 && !plcData && data?.map((log) => (
          <Card
            variant="outlined"
            className={
              toggled
                ? classes.cardToggled
                : classes.cardUntoggled
            }
            key={log._id}
          >
            <CardHeader
              title={
                log.level === "ERROR" ||
                  log.level === "Error" ||
                  log._line.includes("error") ||
                  log._line.includes("Error") ? (
                  "Error"
                ) : (
                  <Typography
                    variant={toggled ? "h4" : "h5"}
                  >
                    {log.timeDifference > trafficLightSystem.day
                      ? "Red"
                      : log.timeDifference < trafficLightSystem.hour
                        ? "Green"
                        : "Amber"}
                  </Typography>
                )
              }
              className={
                log.level === "ERROR" ||
                  log.level === "Error" ||
                  log._line.includes("error") ||
                  log._line.includes("Error") ||
                  log.timeDifference > trafficLightSystem.day
                  ? classes.redCard
                  : log.timeDifference < trafficLightSystem.hour
                    ? classes.greenCard
                    : classes.amberCard
              }
            ></CardHeader>
            <CardContent
              className={
                toggled
                  ? classes.cardContentToggled
                  : classes.cardContentUntoggled
              }
            >
              <Box textAlign="center">
                <Typography
                  variant={toggled ? "h4" : "h5"}
                >
                  {log.timeFromLastGeneratedLabel}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpen(log)}
                  className={
                    toggled
                      ? classes.viewToggled
                      : classes.viewUntoggled
                  }
                >
                  <Typography
                    variant={toggled ? "h5" : "body1"}
                  >
                    View
                  </Typography>
                </Button>
              </Box>
            </CardContent>
          </Card>
        ))}

        <PlcStatusInnerItem plcData={plcData} plcLoading={plcLoading} toggled={toggled} classes={classes} />

      </Box>
      <MonitoringModal
        data={modalData}
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        trafficLightSystem={trafficLightSystem}
      />
    </>
  );
};
